import React, { Fragment, useContext } from "react"
import { useQuery } from "@apollo/client"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import CurrentUserContext from "shared/utils/CurrentUserContext"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Wrapper from "shared/components/Wrapper"
import AdminSubNavigation from "shared/components/AdminSubNavigation"
import StatisticCircle from "shared/components/StatisticCircle"
import HorizontalBarChart from "shared/components/HorizontalBarChart"
import HorizontalRule from "shared/components/HorizontalRule"
import Overlay, { OverlayContainer } from "shared/components/Overlay"
import InsightsHeader from "mill/components/InsightsHeader"
import usePageTitle from "mill/hooks/usePageTitle"
import useFilters from "shared/hooks/useFilters"
import { md } from "mill/utils/breakpoints"
import { ninetyDaysago, today } from "shared/utils/dateHelpers"
import RatingRollingAverageChart from "mill/containers/RatingRollingAverageChart"

import FETCH_ACCOUNT_PERFORMANCE_INSIGHTS from "mill/graphql/FetchAccountPerformanceInsights"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media ${md} {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
`

const PerformanceKnowledge = () => {
  usePageTitle("Performance & knowledge")
  const { currentUser } = useContext(CurrentUserContext)
  const { search } = useLocation()
  const accountId = currentUser.account.id
  const { getParamValue } = useFilters()
  const {
    loading,
    data,
    variables: { startDate, endDate }
  } = useQuery(FETCH_ACCOUNT_PERFORMANCE_INSIGHTS, {
    variables: {
      id: accountId,
      startDate: getParamValue("activeStart") || ninetyDaysago,
      endDate: getParamValue("activeEnd") || today,
      teamIds: getParamValue("teamIds"),
      teamGroupingIds: getParamValue("groupIds"),
      campaignCollectionIds: getParamValue("collectionIds")
    }
  })
  const items = [
    { title: "Engagement", path: `/admin/insights/engagement${search}` },
    {
      title: "Performance & Knowledge",
      path: `/admin/insights/performance-knowledge${search}`
    }
  ]

  const campaignBreakdown =
    data && data.accountInsights
      ? data.accountInsights.campaignPerformanceList
      : []

  return (
    <Fragment>
      <AdminSubNavigation items={items} />
      <Wrapper>
        <Box paddingTop="large" maxWidth="large">
          <Heading level={1}>Performance</Heading>
        </Box>
        <Box paddingBottom="xlarge">
          <InsightsHeader startDate={startDate} endDate={endDate} />
        </Box>

        <OverlayContainer>
          <Box paddingTop="medium" paddingBottom="medium">
            <Grid>
              <Box maxWidth="small" paddingBottom="large">
                <Box paddingBottom="medium">
                  <div>
                    <StatisticCircle
                      color="primary"
                      percentage={
                        data && data.accountInsights.averagePerformanceRate
                      }
                      animate
                      loading={loading}
                    />
                  </div>
                </Box>
                <Heading level={4} align="center" weight="medium">
                  Average performance
                </Heading>
                <Text align="center">
                  The percentage of questions answered correctly by your
                  learners.
                </Text>
              </Box>
              <Box maxWidth="small" paddingBottom="large">
                <Box paddingBottom="medium">
                  <div>
                    <StatisticCircle
                      color="tertiary"
                      percentage={
                        data && data.accountInsights.averageQuestionUplift
                      }
                      animate
                      loading={loading}
                    />
                  </div>
                </Box>
                <Heading level={4} align="center" weight="medium">
                  Average question uplift
                </Heading>
                <Text align="center">
                  The uplift in performance from the first attempt at a question
                  to the second attempt (on embed campaigns).
                </Text>
              </Box>
              <Box maxWidth="small" paddingBottom="large">
                <Box paddingBottom="medium">
                  <div>
                    <StatisticCircle
                      color="quaternary"
                      percentage={
                        data && data.accountInsights.averagePrimerUplift
                      }
                      animate
                      loading={loading}
                    />
                  </div>
                </Box>
                <Heading level={4} align="center" weight="medium">
                  Average primer uplift
                </Heading>
                <Text align="center">
                  The uplift in performance from pre-campaign primer questions
                  to post-campaign primer questions (on embed campaigns).
                </Text>
              </Box>
            </Grid>
          </Box>

          {campaignBreakdown.length > 0 && (
            <React.Fragment>
              <HorizontalRule />
              <Box
                paddingTop="large"
                paddingBottom="large"
                maxWidth="xxlarge"
                marginRight="auto"
                marginLeft="auto">
                <Box paddingBottom="small">
                  <Heading level={3} weight="medium">
                    Breakdown by campaign
                  </Heading>
                </Box>
                <HorizontalBarChart
                  data={campaignBreakdown}
                  animate
                  dataKeys={[
                    "performanceRate",
                    "questionUplift",
                    "primerUplift"
                  ]}
                />
              </Box>
            </React.Fragment>
          )}

          {campaignBreakdown.length === 0 && (
            <Overlay>
              <Box textAlign="center">
                <Heading level={2}>
                  No campaign data available for the selected filters
                </Heading>
                <Box paddingTop="small">
                  <Text>Please change the filters above.</Text>
                </Box>
              </Box>
            </Overlay>
          )}
        </OverlayContainer>

        {data?.viewer?.account?.glickoVisibilityEnabled && (
          <Box paddingTop="large" paddingBottom="xlarge">
            <Heading level={1}>Knowledge</Heading>
            <Text>
              Pairing performance with question difficulty create a knowledge
              index for each topic and track this over time.
            </Text>
            <Box paddingTop="medium">
              <RatingRollingAverageChart accountId={accountId} />
            </Box>
          </Box>
        )}
      </Wrapper>
    </Fragment>
  )
}
export default PerformanceKnowledge
